<template>
  <b-modal
    id="modal-cancel-batch-invoices"
    size="xl"
    centered
    header-bg-variant="light-primary"
    no-close-on-backdrop
    body-class="p-1"
    footer-class="justify-content-between"
    title-class="h2 font-weight-bolder text-dark"
    :title="$t('invoice.cancel.title')"
    hide-footer
    @show="onShowHandle"
  >
    <validation-observer ref="refFormObserver">
      <!-- ANCHOR Table List -->
      <b-table
        ref="refCancelBatchInvoiceListTable"
        style="max-height: 70vh"
        :sticky-header="true"
        class="position-relative"
        :items="invoicesToCancel"
        responsive
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        small
        bordered
        :fields="tableColumns"
      >
        <template
          v-for="column in tableColumns"
          #[`head(${column.key})`]="data"
        >
          <span
            :key="column.key"
            class="text-nowrap"
          >
            {{ $t(`invoice.cancel.${data.column}`) }}
          </span>
        </template>

        <template #cell(no)="data">
          <span class="font-weight-bold">{{ data.index + 1 }}</span>
        </template>

        <template #cell(symbol)="{ item }">
          {{ item.templateNumber }}{{ item.symbol }}
        </template>

        <template #cell(invNumber)="{ item }">
          <div class="text-dark fw-700">
            {{ item.invNumber }}
          </div>
        </template>

        <template #cell(buyerName)="{ item }">
          <div class="text-nowrap">
            {{ item.buyerName }}
          </div>
        </template>

        <template #cell(createdAt)="{ item }">
          <div class="">
            {{ convertISODateTime(item.createdAt).date }}
          </div>
        </template>

        <template #cell(releasedInvCode)="{ item }">
          <div style="max-width: 120px; overflow-wrap: break-word;">
            {{ item.releasedInvCode }}
          </div>
        </template>

        <template #cell(totalAmount)="{ item }">
          <div class="text-right">
            {{ formatCurrency(item.totalAmount) }}
          </div>
        </template>

        <template #cell(tbssStatus)>
          <BBadge
            variant="light-danger"
            pill
          >
            {{ $t('invoice.tbssStatus.notSent') }}
          </BBadge>
        </template>

        <template #cell(receiverName)="{ item, index }">
          <validation-provider
            #default="{ errors }"
            rules=""
            :name="$t('invoice.cancel.receiverName')"
            :vid="`receiverName-${index}`"
          >
            <b-form-group
              label-for="receiverName"
              class="mb-0 min-width-200"
            >
              <b-form-input
                :id="`receiverName-${index}`"
                v-model.trim="item.receiverName"
                :placeholder="$t('invoice.cancel.receiverName')"
                style="overflow: auto"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
        </template>

        <template #cell(email)="{ item, index }">
          <validation-provider
            #default="{ errors }"
            rules="email"
            :name="$t('invoice.cancel.email')"
            :vid="`email-${index}`"
          >
            <b-form-group
              label-for="email"
              class="mb-0 min-width-200"
            >
              <b-form-input
                :id="`email-${index}`"
                v-model.trim="item.email"
                :placeholder="$t('invoice.cancel.email')"
                style="overflow: auto"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
        </template>

        <template #cell(cc)="{ item }">
          <validation-provider
            #default="{ errors }"
            rules=""
            :name="$t('invoice.cancel.cc')"
            vid="cc"
          >
            <b-form-group
              label-for="cc"
              class="mb-0 min-width-200"
            >
              <b-form-input
                id="cc"
                v-model.trim="item.cc"
                :placeholder="$t('invoice.cancel.ccBccJoinCommaPlaceholder')"
                style="overflow: auto"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
        </template>

        <template #cell(bcc)="{ item }">
          <validation-provider
            #default="{ errors }"
            rules=""
            :name="$t('invoice.cancel.bcc')"
            vid="bcc"
          >
            <b-form-group
              label-for="bcc"
              class="mb-0 min-width-200"
            >
              <b-form-input
                id="bcc"
                v-model.trim="item.bcc"
                :placeholder="$t('invoice.cancel.ccBccJoinCommaPlaceholder')"
                style="overflow: auto"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
        </template>

        <template #cell(reason)="{ item , index}">
          <validation-provider
            #default="{ errors }"
            rules="required"
            :name="$t('invoice.cancel.reason')"
            :vid="`reason-${index}`"
          >
            <b-form-group
              label-for="reason"
              class="mb-0 min-width-200"
            >
              <b-form-input
                :id="`reason-${index}`"
                v-model.trim="item.reason"
                :placeholder="$t('invoice.cancel.reason')"
                :state="errors[0] ? false : null"
              />
            </b-form-group>
          </validation-provider>
        </template>

        <template #cell(cancelDate)="{ item }">
          <validation-provider
            #default="{ errors }"
            rules="required"
            :name="$t('invoice.cancel.cancelDate')"
            vid="cancelDate"
          >
            <b-form-group
              label-for="cancelDate"
              class="mb-0"
              style="width: 120px"
            >
              <flat-pickr
                v-model="item.cancelDate"
                class="d-none"
              />
              <div
                class="p-0 form-control"
                :class="errors[0] ? 'border-danger is-invalid' : 'border-transparent'"
              >
                <flat-pickr
                  id="cancelDate"
                  v-model="item.cancelDate"
                  :name="$t('invoice.cancel.cancelDate')"
                  :config="{
                      ...configFlatPickr,
                      altInputClass: 'form-control border-transparent px-50 py-0 height-25rem',
                  }"
                  :placeholder="$t('topup.placeholderSelectDate')"
                />
              </div>
            </b-form-group>
          </validation-provider>
        </template>
      </b-table>

      <div v-if="invoicesNotToCancel?.length">
        {{ $t('invoice.cancel.invoiceCannotCancel') }} <b class="text-info fw-700">({{ invoicesNotToCancel?.length }})</b> -
        <span
          v-for="(invoiceNotToCancelItem, invoiceNotToCancelIndex) in invoicesNotToCancel"
          :key="invoiceNotToCancelIndex"
        >
          <span class="fw-700 font-italic text-danger">{{ invoiceNotToCancelItem }}</span>
          <span v-if="invoiceNotToCancelIndex !== invoicesNotToCancel.length - 1">, </span>
        </span>
      </div>

      <!-- ANCHOR footer button -->
      <div class="d-flex-center justify-content-end gap-2 mt-1">
        <BButton
          variant="flat-dark"
          @click="onCloseHandle"
        >
          {{ $t('close') }}
        </BButton>
        <BButton
          variant="danger"
          :disabled="!invoicesToCancel?.length"
          @click="onSubmitHandle"
        >
          {{ $t('invoice.cancel.title') }}
        </BButton>
      </div>
    </validation-observer>
  </b-modal>
</template>

<script>
import {
  BBadge, BButton, BFormGroup, BFormInput, BModal, BTable,
} from 'bootstrap-vue'
import { computed, ref } from '@vue/composition-api'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import VueI18n from '@/libs/i18n'
import { convertISODateTime, formatCurrency, getToday } from '@/@core/utils/filter'
import { INVOICE_STATUS, RELEASE_STATUS } from '@/constants/invoice'

import formValidation from '@core/comp-functions/forms/form-validation'

import useInvoiceHandle from '../useInvoiceHandle'

export default {
  components: {
    BModal,
    BButton,
    BTable,
    BBadge,
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    flatPickr: () => import('vue-flatpickr-component'),
  },

  props: {
    checkedItems: {
      type: Array,
      default: () => [],
    },
  },

  setup(props, { root }) {
    const { refFormObserver } = formValidation()
    const { cancelBatchInvoice } = useInvoiceHandle()
    const tableColumns = [
      { label: 'no', key: 'no' },
      { label: 'symbol', key: 'symbol' },
      {
        label: 'invNumber', key: 'invNumber', stickyColumn: true, tdClass: 'bg-white', class: 'position-left-45',
      },
      { label: 'createdAt', key: 'createdAt' },
      { label: 'releasedInvCode', key: 'releasedInvCode' },
      { label: 'buyerName', key: 'buyerName' },
      { label: 'totalAmount', key: 'totalAmount' },
      { label: 'tbssStatus', key: 'tbssStatus' },
      { label: 'cancelDate', key: 'cancelDate' },
      { label: 'reason', key: 'reason' },
      { label: 'receiverName', key: 'receiverName' },
      { label: 'email', key: 'email' },
      { label: 'cc', key: 'cc' },
      { label: 'bcc', key: 'bcc' },
    ]

    const invoicesToCancel = ref([])
    const invoicesNotToCancel = ref([])
    function onShowHandle() {
      invoicesNotToCancel.value = props.checkedItems
        .filter(invoiceItem => invoiceItem.releaseStatus !== RELEASE_STATUS.CQT_ISSUED_CODE || invoiceItem.invoiceStatus !== INVOICE_STATUS.SIGNED)
        .map(invoiceItem => invoiceItem.invNumber)
        .sort()
      invoicesToCancel.value = props.checkedItems
        .filter(invoiceItem => invoiceItem.releaseStatus === RELEASE_STATUS.CQT_ISSUED_CODE && invoiceItem.invoiceStatus === INVOICE_STATUS.SIGNED)
        .map(invoiceItem => ({
          ...invoiceItem,
          reason: null,
          sendCancellationNotification: false,
          receiverName: null,
          email: null,
          cc: '',
          bcc: '',
          cancelDate: getToday('YYYY-MM-DD'),
        }))
    }

    function onCloseHandle() {
      root.$bvModal.hide('modal-cancel-batch-invoices')
    }

    async function onSubmitHandle() {
      const valid = await refFormObserver.value.validate()
      if (!valid) return

      try {
        const payload = {
          items: invoicesToCancel.value.map(item => ({
            id: item.id,
            externalId: item.externalId,
            reason: item.reason,
            sendCancellationNotification: true,
            receiverName: item.receiverName,
            email: item.email,
            cc: item.cc,
            bcc: item.bcc,
            cancelDate: `${item.cancelDate}T09:00:00.000Z`,
          })),
        }
        root.$bvModal.show('modal-api-loading')
        await cancelBatchInvoice(payload)
        onCloseHandle()
      } finally {
        root.$bvModal.hide('modal-api-loading')
      }
    }
    const locale = computed(() => VueI18n.locale).value

    const configFlatPickr = ref(
      {
        dateFormat: 'Y-m-d',
        // minDate: 'today',
        // maxDate: 'today',
        locale: locale === 'vi' ? Vietnamese : null,
        allowInput: true,
        altInput: true,
        altFormat: 'd/m/Y',
        shorthandCurrentMonth: false,
        disableMobile: true,
      },
    )
    return {
      onShowHandle,
      tableColumns,
      convertISODateTime,
      formatCurrency,
      onCloseHandle,
      onSubmitHandle,
      invoicesNotToCancel,
      invoicesToCancel,
      refFormObserver,
      configFlatPickr,
    }
  },
}
</script>
